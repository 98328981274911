export default {
    address: "台南市安平區永華路二段503號",
    googleSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.9073906524563!2d120.17289381596507!3d22.990432723295477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e76094667a1d7%3A0x3e6bcd64a684b1a!2zNzA45Y-w5Y2X5biC5a6J5bmz5Y2A5rC46I-v6Lev5LqM5q61NTAz6Jmf!5e0!3m2!1szh-TW!2stw!4v1644404980828!5m2!1szh-TW!2stw",
    googleLink: "https://goo.gl/maps/Mym2zrDP4Aw4Jm8aA",
    phone: "06-2930333",
    fbLink: "https://www.facebook.com/105225588476358/",
    fbMessage: "https://m.me/105225588476358/",
    caseName: "春福御邸",
    indigatorLength: 10,
    houseInfos: [
        ["投資興建", "春福機構"],
        ["建築設計", "大磊聯合建築師事務所"],
        ["企劃銷售", "上揚國際建築團隊"],
        ["接待中心", "台南市安平區永華路二段503號"],
        ["公設設計", "健身房,品茗室,閱覽家教室,閱讀室"],
        ["基地位置", "怡平路X永華八街"],
        ["樓層", "19F/B4"],
        ["戶數", "72戶"],
        ["建照號碼", "(110)南工造字第02377號"],
        ["坪數", "41-52坪"],
        ["行銷企劃", "<img src=" + require("@/projects/cfyd/other.svg") + ">"]
    ],

    gtmCode: [""], // 可放置多個
    recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
    recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
    recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
    order: {
        title: "聯絡我們",
        subTitle: "若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！"
    }
};