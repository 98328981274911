export default [{
        name: "未來門戶",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s2",
        svgSrc: "",
        offset: -100,
        mobileOffset: -100
    },
    {
        name: "精采核心",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s3",
        svgSrc: "",
        offset: -100,
        mobileOffset: -100
    },
    {
        name: "雋永建築",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s4",
        svgSrc: "",
        offset: -100,
        mobileOffset: -100
    },
    {
        name: "品味美學",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s5",
        svgSrc: "",
        offset: -100,
        mobileOffset: -100
    },
    {
        name: "春福鉅作",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s6",
        svgSrc: "",
        offset: -100,
        mobileOffset: -100
    },
    {
        name: "大師齊力",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s7",
        svgSrc: "",
        offset: -100,
        mobileOffset: -100
    }
];