<template>
  <svg
    v-if="!isMobile"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="912.017"
    height="900.451"
    viewBox="0 0 912.017 900.451"
  >
    <defs>
      <clipPath id="a">
        <path
          class="a"
          d="M3444.294,2268.795l-158.706-55.086-127.059,109.9,31.647,164.987,158.706,55.086,127.059-109.9Z"
        />
      </clipPath>
      <clipPath id="b">
        <rect
          class="a"
          width="533.27"
          height="356.501"
          transform="translate(2948.004 2209.005)"
        />
      </clipPath>
      <clipPath id="c">
        <path
          class="a"
          d="M3742.116,2214.636,3583.41,2159.55l-127.059,109.9L3488,2434.437l158.706,55.086,127.059-109.9Z"
        />
      </clipPath>
      <clipPath id="d">
        <rect
          class="a"
          width="517.001"
          height="345.042"
          transform="translate(3326.002 2151.35)"
        />
      </clipPath>
      <clipPath id="e">
        <path
          class="a"
          d="M3840.76,1928.437l-158.706-55.086L3555,1983.251l31.647,164.987,158.706,55.086,127.059-109.9Z"
        />
      </clipPath>
      <clipPath id="f">
        <rect
          class="a"
          width="510.579"
          height="340.558"
          transform="translate(3515.996 1865.995)"
        />
      </clipPath>
      <clipPath id="g">
        <path
          class="a"
          d="M4038.9,2158.811l-158.706-55.086-127.059,109.9,31.647,164.987,158.706,55.086,127.059-109.9Z"
        />
      </clipPath>
      <clipPath id="h">
        <rect
          class="a"
          width="502.232"
          height="334.971"
          transform="translate(3648.996 2102.995)"
        />
      </clipPath>
      <clipPath id="i">
        <path
          class="a"
          d="M3940.921,2443.089,3782.215,2388l-127.059,109.9L3686.8,2662.89l158.706,55.086,127.059-109.9Z"
        />
      </clipPath>
      <clipPath id="j">
        <rect
          class="a"
          width="615.999"
          height="410.905"
          transform="translate(3562.998 2309.997)"
        />
      </clipPath>
      <clipPath id="k">
        <path
          class="a"
          d="M3642.139,2498.915l-158.706-55.086-127.059,109.9,31.647,164.987,158.706,55.086,127.059-109.9Z"
        />
      </clipPath>
      <clipPath id="l">
        <rect
          class="a"
          width="517.394"
          height="345.305"
          transform="translate(3248.999 2434.999)"
        />
      </clipPath>
    </defs>
    <g transform="translate(-6121.529 731.649)">
      <g transform="translate(2963 -2605)">
        <g
          class="b"
          data-aos="fade"
          data-aos-delay="200"
          data-aos-anchor=".s3 .title"
        >
          <g class="c">
            <image
              width="533.27"
              height="356.501"
              transform="translate(2948.004 2209.005)"
              xlink:href="@/projects/cfyd/s3/hex/2.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(6250.104 -366.032) rotate(19.141)">
        <tspan x="0" y="0">新光商圈</tspan>
      </text>
      <g transform="translate(2963 -2605)">
        <g
          class="e"
          data-aos="fade"
          data-aos-delay="500"
          data-aos-anchor=".s3 .title"
        >
          <g class="f">
            <image
              width="517.001"
              height="345.042"
              transform="translate(3326.002 2151.35)"
              xlink:href="@/projects/cfyd/s3/hex/3.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(6541.104 -420.032) rotate(19.141)">
        <tspan x="0" y="0">台南市政府</tspan>
      </text>
      <g transform="translate(2963 -2605)">
        <g
          class="g"
          data-aos="fade"
          data-aos-delay="50"
          data-aos-anchor=".s3 .title"
        >
          <g class="h">
            <image
              width="510.579"
              height="340.558"
              transform="translate(3515.996 1865.995)"
              xlink:href="@/projects/cfyd/s3/hex/1.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(6643.104 -710.033) rotate(19.141)">
        <tspan x="0" y="0">華平公園</tspan>
      </text>
      <g transform="translate(2963 -2605)">
        <g
          class="i"
          data-aos="fade"
          data-aos-delay="800"
          data-aos-anchor=".s3 .title"
        >
          <g class="j">
            <image
              width="502.232"
              height="334.971"
              transform="translate(3648.996 2102.995)"
              xlink:href="@/projects/cfyd/s3/hex/4.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(6841.104 -477.032) rotate(19.141)">
        <tspan x="0" y="0">安平國小</tspan>
      </text>
      <g transform="translate(2963 -2605)">
        <g
          class="k"
          data-aos="fade"
          data-aos-delay="1000"
          data-aos-anchor=".s3 .title"
        >
          <g class="l">
            <image
              width="615.999"
              height="410.905"
              transform="translate(3562.998 2309.997)"
              xlink:href="@/projects/cfyd/s3/hex/5.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(6746.104 -192.032) rotate(19.141)">
        <tspan x="0" y="0">金城國中</tspan>
      </text>
      <g transform="translate(2963 -2605)">
        <g
          class="m"
          data-aos="fade"
          data-aos-delay="1400"
          data-aos-anchor=".s3 .title"
        >
          <g class="n">
            <image
              width="517.394"
              height="345.305"
              transform="translate(3248.999 2434.999)"
              xlink:href="@/projects/cfyd/s3/hex/6.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(6441.104 -137.032) rotate(19.141)">
        <tspan x="0" y="0">市政商圈</tspan>
      </text>
    </g>
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="376.343"
    height="327.733"
    viewBox="0 0 376.343 327.733"
  >
    <defs>
      <clipPath id="a">
        <path
          class="a"
          d="M794.762,1993.027H722.019l-36.371,63,36.371,63h72.743l36.371-63Z"
        />
      </clipPath>
      <clipPath id="b">
        <rect
          class="a"
          width="145.948"
          height="218.812"
          transform="matrix(0.223, -0.975, 0.975, 0.223, 655.14, 2102.639)"
        />
      </clipPath>
      <clipPath id="c">
        <path
          class="a"
          d="M678.9,2060.221H606.162l-36.371,63,36.371,63H678.9l36.371-63Z"
        />
      </clipPath>
      <clipPath id="d">
        <rect
          class="a"
          width="194.574"
          height="129.774"
          transform="translate(543.1 2057.716)"
        />
      </clipPath>
      <clipPath id="e">
        <path
          class="a"
          d="M794.629,1858.482H721.886l-36.371,63,36.371,63h72.743l36.371-63Z"
        />
      </clipPath>
      <clipPath id="f">
        <rect
          class="a"
          width="196.917"
          height="131.421"
          transform="translate(651.815 1854.695)"
        />
      </clipPath>
      <clipPath id="g">
        <path
          class="a"
          d="M678.9,1925.677H606.162l-36.371,63,36.371,63H678.9l36.371-63Z"
        />
      </clipPath>
      <clipPath id="h">
        <rect
          class="a"
          width="199.012"
          height="133.043"
          transform="translate(558.965 1923.294)"
        />
      </clipPath>
      <clipPath id="i">
        <path
          class="a"
          d="M563.9,1993.677H491.162l-36.371,63,36.371,63H563.9l36.371-63Z"
        />
      </clipPath>
      <clipPath id="j">
        <rect
          class="a"
          width="264.842"
          height="176.664"
          transform="translate(440.121 1945)"
        />
      </clipPath>
      <clipPath id="k">
        <path
          class="a"
          d="M564.114,1858.482H491.371l-36.371,63,36.371,63h72.743l36.371-63Z"
        />
      </clipPath>
      <clipPath id="l">
        <rect
          class="a"
          width="201.098"
          height="134.211"
          transform="translate(455.902 1854.789)"
        />
      </clipPath>
    </defs>
    <g transform="translate(247.809 -1846.482)">
      <g transform="translate(-702.599 -12)">
        <g
          class="b"
          data-aos="fade"
          data-aos-delay="0"
          data-aos-anchor=".s3 .title"
        >
          <g class="c">
            <image
              width="218.812"
              height="145.948"
              transform="matrix(0.975, 0.223, -0.223, 0.975, 687.691, 1960.367)"
              xlink:href="@/projects/cfyd/s3/hex/1.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(25.061 1992.17)">
        <tspan x="0" y="0">華平公園</tspan>
      </text>
      <g transform="translate(-702.599 -12)">
        <g
          class="e"
          data-aos="fade"
          data-aos-delay="200"
          data-aos-anchor=".s3 .title"
        >
          <g class="f">
            <image
              width="194.574"
              height="129.774"
              transform="translate(543.1 2057.716)"
              xlink:href="@/projects/cfyd/s3/hex/4.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(-90.797 2059.365)">
        <tspan x="0" y="0">安平國小</tspan>
      </text>
      <g transform="translate(-702.599 -12)">
        <g
          class="g"
          data-aos="fade"
          data-aos-delay="400"
          data-aos-anchor=".s3 .title"
        >
          <g class="h">
            <image
              width="196.917"
              height="131.421"
              transform="translate(651.815 1854.695)"
              xlink:href="@/projects/cfyd/s3/hex/3.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(24.928 1857.626)">
        <tspan x="0" y="0">台南市政府</tspan>
      </text>
      <g transform="translate(-702.599 -12)">
        <g
          class="i"
          data-aos="fade"
          data-aos-delay="600"
          data-aos-anchor=".s3 .title"
        >
          <g class="j">
            <image
              width="199.012"
              height="133.043"
              transform="translate(558.965 1923.294)"
              xlink:href="@/projects/cfyd/s3/hex/2.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(-90.797 1924.82)">
        <tspan x="0" y="0">新光商圈</tspan>
      </text>
      <g transform="translate(-702.599 -12)">
        <g
          class="k"
          data-aos="fade"
          data-aos-delay="800"
          data-aos-anchor=".s3 .title"
        >
          <g class="l">
            <image
              width="264.842"
              height="176.664"
              transform="translate(440.121 1945)"
              xlink:href="@/projects/cfyd/s3/hex/5.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(-205.797 1992.82)">
        <tspan x="0" y="0">金城國中</tspan>
      </text>
      <g transform="translate(-702.599 -12)">
        <g
          class="m"
          data-aos="fade"
          data-aos-delay="1000"
          data-aos-anchor=".s3 .title"
        >
          <g class="n">
            <image
              width="201.098"
              height="134.211"
              transform="translate(455.902 1854.789)"
              xlink:href="@/projects/cfyd/s3/hex/6.jpg"
            />
          </g>
        </g>
      </g>
      <text class="d" transform="translate(-205.587 1857.626)">
        <tspan x="0" y="0">市府商圈</tspan>
      </text>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@import "@/assets/style/function.scss";

.a {
  fill: none;
}
.b {
  clip-path: url(#a);
}
.c {
  clip-path: url(#b);
}
.d {
  fill: #fff;
  font-size: 17px;
  font-family: NotoSansTC-Regular, Noto Sans TC;
}
.e {
  clip-path: url(#c);
}
.f {
  clip-path: url(#d);
}
.g {
  clip-path: url(#e);
}
.h {
  clip-path: url(#f);
}
.i {
  clip-path: url(#g);
}
.j {
  clip-path: url(#h);
}
.k {
  clip-path: url(#i);
}
.l {
  clip-path: url(#j);
}
.m {
  clip-path: url(#k);
}
.n {
  clip-path: url(#l);
}

@media only screen and (max-width: 767px) {
  .a {
    fill: none;
  }
  .b {
    clip-path: url(#a);
  }
  .c {
    clip-path: url(#b);
  }
  .d {
    fill: #fff;
    font-size: 9.541px;
    font-family: NotoSansTC-Regular, Noto Sans TC;
  }
  .e {
    clip-path: url(#c);
  }
  .f {
    clip-path: url(#d);
  }
  .g {
    clip-path: url(#e);
  }
  .h {
    clip-path: url(#f);
  }
  .i {
    clip-path: url(#g);
  }
  .j {
    clip-path: url(#h);
  }
  .k {
    clip-path: url(#i);
  }
  .l {
    clip-path: url(#j);
  }
  .m {
    clip-path: url(#k);
  }
  .n {
    clip-path: url(#l);
  }
}
</style>

<script>
import { isMobile } from "@/utils";
export default {
  name: "honeycomb",
  data() {
    return {
      isMobile,
    };
  },
  mounted() {
    AOS.refreshHard();
  }
};
</script>